import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import moment from 'moment';
import { Timing } from '../../lib/util';
import Card from '../../models/Card';
import CardTemplate from '../../models/CardTemplate';
import Signature from '../../models/Signature';
import ErrorResponse from '../../network/responses/ErrorResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import manageCardAsyncActions from '../actions/manageCard.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA } from '../types';

export interface ManageCardState extends Card {
	updatedAt: number;
	socketUpdatedAt: number;
}

const initialState: ManageCardState = {
	id: '',
	uuid: '',
	template: null,
	creator: {
		id: '',
		firstName: '',
		lastName: '',
		email: '',
		optin: true,
	},
	recipientFirstName: '',
	recipientLastName: '',
	recipientEmail: '',
	image: '',
	isContributionEnabled: true,
	contribution: 0,
	deliveryAt: moment().add(1, 'day').format('DD/MM/YYYY') + '12:00 PM',
	deliveryTimezone: '',
	isReminderEnabled: true,
	reminderMessage: '',
	contributors: [],
	signatures: [],
	thankYouMessageSent: false,
	withdrawalLocked: false,
	yourContribution: 0,
	collectedAmount: 0,
	deliveredAt: null,
	updatedAt: Timing.now(),
	socketUpdatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'manageCard',
	initialState,
	reducers: {
		updateSignature: (state: WritableDraft<typeof initialState>, action: PayloadAction<Signature>) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex === -1) {
				state.signatures.push(action.payload);
			} else {
				state.signatures.splice(findIndex, 1, action.payload);
			}

			state.socketUpdatedAt = Timing.now();
		},
		moveSignature: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Signature, 'id' | 'position'>>
		) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1, {
					...state.signatures[findIndex],
					position: action.payload.position,
				});

				state.socketUpdatedAt = Timing.now();
			}
		},
		metaUpdateSignature: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Signature, 'id' | 'meta'>>
		) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1, {
					...state.signatures[findIndex],
					meta: action.payload.meta,
				});

				state.socketUpdatedAt = Timing.now();
			}
		},
		socketRemoveSignature: (state: WritableDraft<typeof initialState>, action: PayloadAction<string>) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload, 10)
			);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1);
				state.socketUpdatedAt = Timing.now();
			}
		},
		reset: () => initialState,
		setTemplate: (state: WritableDraft<typeof initialState>, action: PayloadAction<CardTemplate>) => {
			state.template = action.payload;
			state.image = action.payload.picture;
			state.updatedAt = Timing.now();
		},
		changeCover: (state: WritableDraft<typeof initialState>, action: PayloadAction<string>) => {
			state.template = null;
			state.image = action.payload;
			state.updatedAt = Timing.now();
		},
		updateRecipient: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<
				Pick<
					Card,
					'recipientFirstName' | 'recipientLastName' | 'recipientEmail' | 'deliveryAt' | 'deliveryTimezone'
				>
			>
		) => {
			state.recipientFirstName = action.payload.recipientFirstName;
			state.recipientLastName = action.payload.recipientLastName;
			state.recipientEmail = action.payload.recipientEmail;
			state.deliveryAt = action.payload.deliveryAt;
			state.deliveryTimezone = action.payload.deliveryTimezone;

			if (state.reminderMessage === '') {
				state.reminderMessage = `Hi there. You have been invited to Sign the Card for ${action.payload.recipientFirstName} ${action.payload.recipientLastName}.`;
			}
			state.updatedAt = Timing.now();
		},
		updateContribution: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'contribution' | 'isContributionEnabled'>>
		) => {
			state.contribution = action.payload.contribution;
			state.isContributionEnabled = action.payload.isContributionEnabled;
			state.updatedAt = Timing.now();
		},
		updateContributors: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'contributors'>>
		) => {
			state.contributors = action.payload.contributors;
			state.updatedAt = Timing.now();
		},
		updateReminder: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Card, 'isReminderEnabled' | 'reminderMessage'>>
		) => {
			state.isReminderEnabled = action.payload.isReminderEnabled;
			state.reminderMessage = action.payload.reminderMessage;
			state.updatedAt = Timing.now();
		},
	},
	extraReducers: {
		[manageCardAsyncActions.show.fulfilled.type]: (state, action: CPA<Card>) => {
			state.id = action.payload.id;
			state.uuid = action.payload.uuid;
			state.template = action.payload.template;
			state.creator = action.payload.creator;
			state.recipientFirstName = action.payload.recipientFirstName;
			state.recipientLastName = action.payload.recipientLastName;
			state.recipientEmail = action.payload.recipientEmail;
			state.image = action.payload.image;
			state.isContributionEnabled = action.payload.isContributionEnabled;
			state.contribution = action.payload.contribution;
			state.deliveryAt = action.payload.deliveryAt;
			state.deliveryTimezone = action.payload.deliveryTimezone;
			state.isReminderEnabled = action.payload.isReminderEnabled;
			state.reminderMessage = action.payload.reminderMessage;
			state.contributors = action.payload.contributors;
			state.signatures = action.payload.signatures;
			state.thankYouMessageSent = action.payload.thankYouMessageSent;
			state.withdrawalLocked = action.payload.withdrawalLocked;
			state.yourContribution = action.payload.yourContribution;
			state.collectedAmount = action.payload.collectedAmount;
			state.deliveredAt = action.payload.deliveredAt;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[manageCardAsyncActions.show.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[manageCardAsyncActions.update.fulfilled.type]: (state, action: CPA<Card>) => {
			state.id = action.payload.id;
			state.uuid = action.payload.uuid;
			state.template = action.payload.template;
			state.creator = action.payload.creator;
			state.recipientFirstName = action.payload.recipientFirstName;
			state.recipientLastName = action.payload.recipientLastName;
			state.recipientEmail = action.payload.recipientEmail;
			state.image = action.payload.image;
			state.isContributionEnabled = action.payload.isContributionEnabled;
			state.contribution = action.payload.contribution;
			state.deliveryAt = action.payload.deliveryAt;
			state.deliveryTimezone = action.payload.deliveryTimezone;
			state.isReminderEnabled = action.payload.isReminderEnabled;
			state.reminderMessage = action.payload.reminderMessage;
			state.contributors = action.payload.contributors;
			state.signatures = action.payload.signatures;
			state.thankYouMessageSent = action.payload.thankYouMessageSent;
			state.withdrawalLocked = action.payload.withdrawalLocked;
			state.yourContribution = action.payload.yourContribution;
			state.collectedAmount = action.payload.collectedAmount;
			state.deliveredAt = action.payload.deliveredAt;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[manageCardAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[manageCardAsyncActions.addContributors.fulfilled.type]: (state, action: CPA<Card>) => {
			state.contribution = action.payload.contribution;
			state.contributors = action.payload.contributors;
			state.signatures = action.payload.signatures;
			state.thankYouMessageSent = action.payload.thankYouMessageSent;
			state.withdrawalLocked = action.payload.withdrawalLocked;
			state.yourContribution = action.payload.yourContribution;
			state.collectedAmount = action.payload.collectedAmount;
			state.deliveredAt = action.payload.deliveredAt;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[manageCardAsyncActions.addContributors.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[manageCardAsyncActions.removeContributors.fulfilled.type]: (state, action: CPA<Card>) => {
			state.contribution = action.payload.contribution;
			state.contributors = action.payload.contributors;
			state.signatures = action.payload.signatures;
			state.thankYouMessageSent = action.payload.thankYouMessageSent;
			state.withdrawalLocked = action.payload.withdrawalLocked;
			state.yourContribution = action.payload.yourContribution;
			state.collectedAmount = action.payload.collectedAmount;
			state.deliveredAt = action.payload.deliveredAt;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[manageCardAsyncActions.removeContributors.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
	},
});

export const manageCardActions = slice.actions;

export default slice.reducer;
