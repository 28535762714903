import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import moment from 'moment';
import { Timing } from '../../lib/util';
import Card from '../../models/Card';
import Signature from '../../models/Signature';
import MoveSignaturesToNewPageRequest from '../../network/requests/MoveSignaturesToNewPageRequest';
import ErrorResponse from '../../network/responses/ErrorResponse';
import OkResponse from '../../network/responses/OkResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import signACardAsyncActions from '../actions/signACard.action';
import transactionsAsyncActions from '../actions/transactions.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA } from '../types';

export interface SignACardState extends Card {
	unsavedChanges: boolean;
	updatedAt: number;
	socketUpdatedAt: number;
}

const initialState: SignACardState = {
	id: '',
	uuid: '',
	template: null,
	creator: {
		id: '',
		firstName: '',
		lastName: '',
		email: '',
		optin: true,
	},
	recipientFirstName: '',
	recipientLastName: '',
	recipientEmail: '',
	image: '',
	isContributionEnabled: true,
	contribution: 0,
	deliveryAt: moment().add(1, 'day').format('DD/MM/YYYY') + '12:00 PM',
	deliveryTimezone: '',
	isReminderEnabled: true,
	reminderMessage: '',
	contributors: [],
	signatures: [],
	thankYouMessageSent: false,
	withdrawalLocked: false,
	yourContribution: 0,
	collectedAmount: 0,
	deliveredAt: null,
	unsavedChanges: false,
	updatedAt: moment().valueOf(),
	socketUpdatedAt: moment().valueOf(),
};

const slice = createSlice({
	name: 'signACard',
	initialState,
	reducers: {
		socketUpdateSignature: (state: WritableDraft<typeof initialState>, action: PayloadAction<Signature>) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex === -1) {
				state.signatures.push(action.payload);
			} else {
				state.signatures.splice(findIndex, 1, action.payload);
			}

			state.socketUpdatedAt = Timing.now();
		},
		socketMoveSignature: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Signature, 'id' | 'position'>>
		) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1, {
					...state.signatures[findIndex],
					position: action.payload.position,
				});

				state.socketUpdatedAt = Timing.now();
			}
		},
		socketMetaUpdateSignature: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Signature, 'id' | 'meta'>>
		) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1, {
					...state.signatures[findIndex],
					meta: action.payload.meta,
				});

				state.socketUpdatedAt = Timing.now();
			}
		},
		socketRemoveSignature: (state: WritableDraft<typeof initialState>, action: PayloadAction<string>) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload, 10)
			);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1);
				state.socketUpdatedAt = Timing.now();
			}
		},
		updateSignature: (state: WritableDraft<typeof initialState>, action: PayloadAction<Signature>) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex === -1) {
				state.signatures.push(action.payload);
			} else {
				state.signatures.splice(findIndex, 1, action.payload);
			}

			state.unsavedChanges = true;
			state.updatedAt = Timing.now();
		},
		moveSignature: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<Pick<Signature, 'id' | 'position'>>
		) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1, {
					...state.signatures[findIndex],
					position: action.payload.position,
				});

				state.unsavedChanges = true;
				state.updatedAt = Timing.now();
			}
		},
		moveSignaturesToNewPage: (
			state: WritableDraft<typeof initialState>,
			action: PayloadAction<MoveSignaturesToNewPageRequest>
		) => {
			// const newSignatures = [...state.signatures];
			for (const id of action.payload.ids) {
				const findIndex = state.signatures.findIndex((element) => element.id === id);
				if (findIndex !== -1) {
					state.signatures.splice(findIndex, 1, {
						...state.signatures[findIndex],
						page: action.payload.page,
					});
				}
			}

			state.unsavedChanges = true;
			// state.signatures = newSignatures;
			state.updatedAt = Timing.now();
		},
		removeSignature: (state: WritableDraft<typeof initialState>, action: PayloadAction<string>) => {
			const findIndex = state.signatures.findIndex((element) => element.id === action.payload);
			if (findIndex !== -1) {
				state.signatures.splice(findIndex, 1);
				state.updatedAt = Timing.now();
			}
		},
		reset: () => initialState,
	},
	extraReducers: {
		[signACardAsyncActions.show.fulfilled.type]: (state, action: CPA<Card>) => {
			state.id = action.payload.id;
			state.uuid = action.payload.uuid;
			state.template = action.payload.template;
			state.creator = action.payload.creator;
			state.recipientFirstName = action.payload.recipientFirstName;
			state.recipientLastName = action.payload.recipientLastName;
			state.recipientEmail = action.payload.recipientEmail;
			state.image = action.payload.image;
			state.isContributionEnabled = action.payload.isContributionEnabled;
			state.contribution = action.payload.contribution;
			state.deliveryAt = action.payload.deliveryAt;
			state.deliveryTimezone = action.payload.deliveryTimezone;
			state.isReminderEnabled = action.payload.isReminderEnabled;
			state.reminderMessage = action.payload.reminderMessage;
			state.contributors = action.payload.contributors;
			state.signatures = action.payload.signatures;
			state.thankYouMessageSent = action.payload.thankYouMessageSent;
			state.withdrawalLocked = action.payload.withdrawalLocked;
			state.yourContribution = action.payload.yourContribution;
			state.collectedAmount = action.payload.collectedAmount;
			state.deliveredAt = action.payload.deliveredAt;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[signACardAsyncActions.show.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[signACardAsyncActions.createSignature.fulfilled.type]: (state, action: CPA<Signature>) => {
			const findIndex = state.signatures.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.id, 10)
			);
			if (findIndex === -1) {
				state.signatures.push(action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action, {
				id: action.payload.id,
				sessionId: action.payload.sessionId,
			});
		},
		[signACardAsyncActions.createSignature.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[signACardAsyncActions.updateSignatures.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			state.unsavedChanges = false;

			postRequest(action);
		},
		[signACardAsyncActions.updateSignatures.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[signACardAsyncActions.moveSignaturesToNewPage.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			postRequest(action);
		},
		[signACardAsyncActions.moveSignaturesToNewPage.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[signACardAsyncActions.publishSignatures.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[signACardAsyncActions.publishSignatures.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[signACardAsyncActions.removeSignature.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[signACardAsyncActions.removeSignature.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[signACardAsyncActions.moderateRemoveSignature.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[signACardAsyncActions.moderateRemoveSignature.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[signACardAsyncActions.moderateMoveSignature.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[signACardAsyncActions.moderateMoveSignature.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[transactionsAsyncActions.finalize.fulfilled.type]: (state, action: CPA<Card>) => {
			if ('id' in action.payload && 'yourContribution' in action.payload) {
				state.yourContribution = action.payload.yourContribution;
				state.updatedAt = Timing.now();
			}
		},
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
	},
});

export const signACardActions = slice.actions;

export default slice.reducer;
