import React from 'react';
import Box from '../../lib/components/Box';
import Header from '../../lib/components/Header';
import Typography from '../../lib/components/Typography';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import PayPalLogo from './../../img/il-paypal-logo.svg';
import styles from './index.module.scss';

const MaintenanceScreen: React.FC = () => {
	const { width } = useWindowDimensions();

	return (
		<div style={{ height: '100%', overflow: 'hidden', overflowY: 'auto' }}>
			<section className={styles.container}>
				<div className={styles.backgroundLeft} />
				<div className={styles.backgroundRight} />
				<Header
					hideAuthentication={process.env.REACT_APP_APP_ENV === 'maintenance'}
					style={{ zIndex: 5, position: 'static', top: 0, padding: '16px 16px' }}
				/>
				<div className={styles.mainContainer}>
					<section className={styles.rightContainer}>
						<Typography variant="body2" style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
							We`re coming back soon!
						</Typography>
						<Typography variant={width < 576 ? 'h2' : 'h1'} style={{ textAlign: 'center' }}>
							Under
							<br />
							Maintenance
						</Typography>
					</section>
				</div>
				<Box
					style={{
						position: 'relative',
						zIndex: 5,
						padding: width < 992 ? '16px 0' : '32px',
						textAlign: 'center',
						display: 'flex',
						flexDirection: width < 992 ? 'column' : 'row',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{width >= 992 && <Box style={{ flexGrow: 1 }} />}
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="body1" style={{ fontSize: '0.917rem' }}>
							All card payments made securely with
						</Typography>
						<Box width={12} />
						<img style={{ height: width < 576 ? 20 : 25 }} src={PayPalLogo} alt={'PayPal'} />
					</div>
					{width >= 992 && <Box style={{ flexGrow: 1 }} />}
					{width < 992 && <Box height={16} />}
					<Typography variant="body1" style={{ fontSize: '0.917rem', flexShrink: 0 }}>
						&copy; 2021 Sign the Card
					</Typography>
				</Box>
			</section>
		</div>
	);
};

export default MaintenanceScreen;
